<template>
    <div class="commandBox">
      <div class="close-button" @click="closeCop">
        <i class="el-icon-circle-close"></i>
      </div>
      <div class="body">
        <div class="content">
          <div class="item" v-for="(item, index ) in commanderList" :key="index" :style="{width: (36 / commanderList.length) + 'vw', height: (36 / commanderList.length / 4 * 5) + 'vw'}">
            <!-- <div class="commander-pic"  :style="getImgStyle(item.src)" > </div> -->
            <div @click="goDetail(item)">
              <img class="item-img" :src="item.imgName" :style="{width: (36 / commanderList.length) + 'vw', height: (36 / commanderList.length / 4 * 5) + 'vw'}"/>
              <div style="width: 100%; font-size: 12px; text-align: center;">
                <!-- <div style="width: 30%;height: 26px; color: #fff;">姓名</div> -->
                <div style="width: 100%; height:30px; color: #fff; word-wrap: break-all; margin-top: 10px; ">
                  {{ item.name }}</div>
              </div>
              <div style="width: 100%; display: flex; word-wrap: break-all;font-size: 16px; text-align: center;">
                <!-- <div style="width: 30%;height: 26px; color: #fff;">职位</div> -->
                <div style="width: 100%; color: #fff;">{{ item.cnPosition }}</div>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        props: ['commanderList'],
        data(){
            return {
                // commanderList: [
//                     {
//                         src: require("../assets/img/ddg93-1.png"),
//                         name: 'CDR Kevin M. Schaeffer',
//                         cnName: '',
//                         cnPosition: '舰长',
//                         position: 'Commanding Officer',
//                         detail: `A native of Alexandria, VA, Commander Kevin Schaeffer graduated from the United States Naval Academy in 2004 with a Bachelor of Science Degree in Computer Science.  His first tour of duty was the Naval Postgraduate School in Monterey, California where he received a Master’s Degree in Systems Technology.  
 
//  Commander Schaeffer’s operational assignments include Division Officer tours as the Auxiliaries Officer, Electrical Officer and Navigator in USS Lake Erie (CG 70) and Department Head tours in USS Port Royal (CG 73) as Weapons Officer and Combat Systems Officer.  Additionally, Commander Schaeffer served in support of Operation ENDURING FREEDOM, as part the J3 in Combined Joint Task Force Paladin, the counter-IED Task Force in Afghanistan.
  
//  Ashore, Commander Schaeffer served in the Plans and Policy Directorate (N5) at U.S. Pacific Fleet from 2010 to 2012 and in the Future Operations Directorate (J35) at U.S. Indo-Pacific Command from 2015 to 2018.  Commander Schaeffer also served as the Executive Officer and Operations Officer at Afloat Training Group Middle Pacific from 2018 to 2020.  Most recently, he served as the Executive Officer of USS Chung-Hoon (DDG 93).
 
//  Commander Schaeffer’s military awards include the Defense Meritorious Service Medal, Meritorious Service Medal, Joint Service Commendation Medal, Navy and Marine Corps Commendation Medal, and the Navy and Marine Corps Achievement Medal, as well as various campaign and unit awards.`,
//                       cnDetail: `Kevin Schaeffer 指挥官出生于弗吉尼亚州亚历山大市，2004 年毕业于美国海军学院，获得计算机科学学士学位。他的第一次任期是在加利福尼亚州蒙特雷的海军研究生院，在那里他获得了系统技术硕士学位。
 
//  Schaeffer 指挥官的作战任务包括在 USS 伊利湖 (CG 70) 担任辅助军官、电气官和领航员，以及在 USS Port Royal (CG 73) 担任武器官和战斗系统官的部门负责人。此外，Schaeffer 指挥官作为联合特遣部队圣骑士 J3 的一部分，支持持久自由行动，这是阿富汗的反简易爆炸装置特遣部队。
  
//  在海上，Schaeffer 司令于 2010 年至 2012 年在美国太平洋舰队的计划与政策局 (N5) 任职，并于 2015 年至 2018 年在美国印太司令部的未来作战局 (J35) 任职。Schaeffer 司令还担任执行官2018 年至 2020 年在 Afloat Training Group Middle Pacific 担任运营官和运营官。最近，他担任 USS Chung-Hoon (DDG 93) 的执行官。
 
//  谢弗指挥官的军事奖项包括国防功勋奖章、功勋奖章、联合服役嘉奖奖章、海军和海军陆战队嘉奖奖章、海军和海军陆战队成就奖章，以及各种战役和单位奖项。`
        
//                     },
//                     {
//                         src: require("../assets/img/ddg93-2.png"),
//                         name: 'CDR Peter Schunk',
//                         cnName: '',
//                         cnPosition: '副舰长',
//                         position: 'Executive Officer',
//                         detail: `A native of Milwaukee, Wisconsin Commander Schunk graduated from Marquette University NROTC in 2007 with a Bachelor of Science degree in Information Technology. In 2011, he graduated from the Defense Language Institute Foreign Language Center with an Associate’s degree in German Language and Culture. In 2018, he graduated with distinction from the United States Naval War College with a Master of Arts degree in Defense and Strategic Studies.

// Afloat, Commander Schunk served aboard USS HALSEY (DDG 97) as the Ordnance and VBSS Officer and then with MCM CREW CONSTANT embarked in USS WARRIOR (MCM 10), USS CHIEF (MCM 14), and USS DEXTROUS (MCM 13) as the Operations Officer. He completed his department head tours aboard USS HOWARD (DDG 83) and USS MOBILE BAY (CG 53) as the Engineer Officer. During his sea tours, he completed three deployments, first in support of Operation Enduring Freedom, to the Horn of Africa with the PELELIU ESG, then to the Arabian Gulf in support of Commander Task Force 52, and finally to the Western Pacific and South China Sea with the JOHN C. STENNIS CSG. Additionally, Commander Schunk served as a Personnel Exchange Program Officer aboard German Minehunter FGS WEILHEIM (M 1059) and later as the Assistant Operations Officer on the staff of Mine Countermeasures Squadron 3 in Kiel, Germany. 

// Ashore, Commander Schunk served in the N3 department of Surface and Mine Warfighting Development Center’s Sea Combat Division as an ASW/ASUW Warfare Tactics Instructor. He also served as a Legislative Fellow in the office of Senator Susan Collins (R-ME).

// Commander Schunk’s personal awards include the Navy and Marine Corps Commendation Medal (with three Gold Stars) and Navy and Marine Corps Achievement Medal (with Gold Star) as well as multiple unit and service awards.`,
//                         cnDetail: `Schunk是威斯康星州密尔沃基市人，指挥官于2007年毕业于马凯特大学海军陆战队，获得信息技术理科学士学位。2011年，他从国防语言学院外语中心毕业，获得德语语言和文化副学士学位。2018年，他以优异的成绩毕业于美国海军战争学院，获得国防和战略研究的文学硕士学位。

// 在海上，舒克指挥官曾在美国海军哈尔西号（DDG 97）上担任军械和VBSS官员，然后与MCM CREW CONSTANT一起登上美国海军勇士号（MCM 10）、美国海军CHIEF号（MCM 14）和美国海军DEXTROUS号（MCM 13）担任操作官员。他在美国海军HOWARD号（DDG 83）和美国海军MOBILE BAY号（CG 53）上完成了他的部门主管任务，担任工程官。在出海期间，他完成了三次部署，首先是支持 "持久自由行动"，与佩利欧ESG一起前往非洲之角，然后前往阿拉伯湾支持指挥官52特遣部队，最后与约翰-C-斯滕尼斯CSG一起前往西太平洋和南中国海。此外，Schunk指挥官曾在德国猎雷舰FGS WEILHEIM（M 1059）上担任人员交流项目官员，后来在德国基尔的反水雷中队3的工作人员中担任助理行动官员。

// 在海上，舒克指挥官在水陆作战发展中心的海战部门的N3部门担任反潜/反舰作战战术教官。他还曾在参议员苏珊-柯林斯（R-ME）的办公室担任立法研究员。

// 舒克指挥官的个人奖项包括海军和海军陆战队嘉奖勋章（含三颗金星）和海军和海军陆战队成就勋章（含金星）以及多个单位和服务奖项。`
//                     },
//                     {
//                         src: require("../assets/img/ddg93-3.png"),
//                         name: 'CMDCM(SW/EXW/NAC) William P. Eickhoff',
//                         cnName: '',
//                         cnPosition: '指挥军士长',
//                         position: 'Command Master Chief',
//                         detail: `A native of southern California, Master Chief Eickhoff joined the Navy in July, 1996.  After his initial training, he reported to his first tour at Naval Medical Center San Diego as a Hospital Corpsman.  

// Master Chief Eickhoff’s operational assignements include Search and Rescue Corpsman at Roosevelt Roads, Puerto Rico, Independent Duty Corpsman (IDC) on the USS SCOUT (MCM 8), IDC and Mountaineer at the Joint POW/MIA Accounting Command, and the Medial Department Head and Company Senior Enlisted Advisor at Coastal Riverine Squadron THREE. 

// Ashore, Master Chief Eickhoff served as an IDC at the Branch Health Clinic, Brunswick, Maine and the Naval School of Explosive Ordnance Disposal in Destin, Florida.  Additionally, he served as the Pacific Fleet Master Chief’s Executive Assistant during his first tour as a Command Senior Chief.  Most recently, Master Chief Eickhoff was the Command Senior Enlisted Leader at the Pacific Missile Range Facility, Barking Sands, Kauai.   

// He has qualified as Expeditionary Warfare Specialist, Enlisted Surface Warfare Specialist, and Naval Aircrew.  He has earned numerous medals and unit campaign awards thought his career and holds a Bachelor of Science from George Washington University. He is also a graduate from the U.S. Navy’s Senior Enlisted Academy, as well as the Command Master Chief/Chief of the Boat Course. 

// He currently serves onboard USS CHUNG HOON (DDG 93) as the Command Master Chief.`,
//                         cnDetail: `艾克霍夫少校是南加州人，于1996年7月加入海军。 在最初的培训后，他在圣地亚哥海军医疗中心报到，成为一名医院护理员。 

// 艾克霍夫士官长的工作任务包括在波多黎各罗斯福路的搜索和救援人员，美国海军SCOUT号（MCM 8）的独立值班人员（IDC），联合战俘/MIA核算司令部的IDC和登山者，以及沿海河流中队THREE的医疗部门负责人和公司高级顾问。

// 在海上，艾克霍夫少校曾在缅因州布伦瑞克的分院卫生所和佛罗里达州德斯坦的海军爆炸物处理学校担任IDC。 此外，他在第一次担任司令部高级长官期间担任太平洋舰队总司令的行政助理。 最近，艾克霍夫少校在考艾岛巴金沙的太平洋导弹靶场设施担任司令部高级领导。  

// 他有资格成为远征作战专家、入伍水面作战专家和海军空勤人员。 在他的职业生涯中，他获得了许多奖章和单位战役奖，并拥有乔治-华盛顿大学的理学士学位。他还毕业于美国海军高级军官学院，以及指挥部主官/舰长课程。

// 他目前在美国海军忠勋号（DDG 93）上担任指挥部主任。`
//                     }
                    
                // ],
                
            }
        },
        created(){
          
        },
        mounted(){

        },
        methods:{
          getImgStyle(src) {
            return {
              'background-image': 'url(' + src + ')'
            }
          },
          closeCop(){
            this.$emit('closeCop');
          },
          goDetail(item) {
            this.$emit("itemClick", item.id);
          },
        }
    }
</script>

<style lang="less" scoped>
  .commandBox{
    z-index: 9520;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 50vw;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.7);
    // box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
    //       2px 0 3px -1px gray;
    box-shadow: 8px 8px 5px #888888;
    border-radius: 10px;
    .close-button {
      position: absolute;
      top: 1px;
      right: 2px;
      z-index: 9277;
      font-size: 30px;
      color: #fff;
    }
    .body{
      width: 100%;
      height: 100%;
      position: relative;
      .content{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 70%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item{
          margin-top: 5%;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 5px;
          padding-right: 5px;
          .commander-pic{
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
          }

          .item-img{
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
          }


          .item-img:hover{transform:scale(1.05)} 
        }
      }
    }
  }
</style>